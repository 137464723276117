export default {
    data() {
      return {
          rem100: null
      }
    },
    methods: {
        cloneOverJson(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        inRem(px) {
            if (!this.rem100) {
                this.rem100 = this.$refs.remEx.offsetHeight
            }
            return this.rem100 * px / 100
        },
        getuserID() {
            let userID = window.localStorage.getItem('userID')
            if (!userID) {
                userID = Math.random().toString(16).slice(2)
                window.localStorage.setItem('userID', userID)

                return userID
            }

            return userID
        },
        async track({e, screen, event, shown = []}) {

           let id = '', p = '', stellage = ''

            if (e) {
                id = e.className.split(' ')[1]
                p = e.closest('.drag-shelf').className.split(' ')[1]
                stellage = e.closest('.drag-column').className.split(' ')[1]
            }

            const userID = this.getuserID()
            const timestamp = new Date().toISOString()

            const params = new URLSearchParams(window.location.search);
            const con = params.get('con')
            const primaryID = params.get('ID')

            // fetch request timeout
            const controller = new AbortController()
            const timeoutId = setTimeout(() => controller.abort(), 1500)

            await fetch('https://ccscc.store/add_to_basket?' + new URLSearchParams({
                event,
                userID,
                primaryID,
                id,
                timestamp,
                con,
                p,
                stellage,
                screen,
                shown: shown.join(",")
            }), { signal: controller.signal })
            clearTimeout(timeoutId)
        }
    }
}