<template>
  <div class="horizontal-scroll"
       @wheel.prevent="wheel"
       @scroll.prevent="wheel"
       ref="horizontal">
    <div class="js-rem-example" ref="remEx"></div>
    <component v-if="zones && zones.length > 0" :is="screenCase">
      <DragList
          :initialZones="initialZones"
          :zones="zones"
          :ready="ready"
          :options="options"
          :basketItemsQnty="basketItemsQnty"
      />
    </component>
    <Arrow class="_left"
           v-if="isTouch ? horPosition > scrollGap : horPosition > maxScrollLeft - scrollGap"
           @click.native="scrollHorizontal('left')" />
    <Arrow class="_right"
           v-if="isTouch ? horPosition < maxScrollLeft - scrollGap : horPosition < scrollGap"
           @click.native="scrollHorizontal('right')" />
    <a class="link-button button" @click="nextScreen" v-if="basketItemsQnty">
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
        <path d="M4.9673 16.8H2.5999C2.21322 16.8 1.8999 17.1133 1.8999 17.5C1.8999 17.8866 2.21322 18.2 2.5999 18.2H5.3999C5.66506 18.2 5.90754 18.0502 6.02598 17.813L6.7999 16.2654L8.97382 20.613C9.09646 20.8583 9.3437 21 9.60046 21C9.70574 21 9.81242 20.9762 9.91294 20.9258C10.2585 20.753 10.3987 20.3324 10.226 19.9866L7.5825 14.7L8.28642 13.2918L9.01722 14.388C9.14714 14.5826 9.36554 14.6997 9.59962 14.6997H11.6999C12.0866 14.6997 12.3999 14.3864 12.3999 13.9997C12.3999 13.613 12.0866 13.2997 11.6999 13.2997H9.97454L8.7823 11.5113C8.65518 11.3209 8.42838 11.2 8.1999 11.2H5.3999C5.14202 11.2 4.88918 11.3562 4.77382 11.5869L3.72382 13.6869C3.55106 14.0327 3.69134 14.4533 4.03686 14.626C4.37818 14.7966 4.80518 14.6543 4.97598 14.313L5.8325 12.6H7.06702L4.9673 16.8Z"/>
        <path d="M8.19995 9.7999C8.19995 10.5719 8.82799 11.1999 9.59995 11.1999C10.3719 11.1999 11 10.5719 11 9.7999C11 9.02794 10.3719 8.3999 9.59995 8.3999C8.82799 8.3999 8.19995 9.02794 8.19995 9.7999Z"/>
        <path d="M13.8 14C13.8 14.3867 14.1134 14.7 14.5 14.7H21.9102L19.6053 17.0049C19.3317 17.2782 19.3317 17.7215 19.6053 17.9947C19.7419 18.1317 19.9208 18.2 20.1 18.2C20.2792 18.2 20.4582 18.1317 20.5951 17.995L24.0951 14.495C24.3686 14.2217 24.3686 13.7785 24.0951 13.5052L20.5951 10.0052C20.3215 9.73166 19.8786 9.73166 19.6053 10.0052C19.3317 10.2785 19.3317 10.7217 19.6053 10.995L21.9102 13.3H14.5C14.1134 13.3 13.8 13.6133 13.8 14Z"/>
        <path d="M26.3999 5.6001H16.5999C16.2132 5.6001 15.8999 5.91342 15.8999 6.3001V11.2001C15.8999 11.5868 16.2132 11.9001 16.5999 11.9001C16.9866 11.9001 17.2999 11.5868 17.2999 11.2001V7.0001H25.6999V21.0001H17.2999V16.8001C17.2999 16.4134 16.9866 16.1001 16.5999 16.1001C16.2132 16.1001 15.8999 16.4134 15.8999 16.8001V21.7001C15.8999 22.0868 16.2132 22.4001 16.5999 22.4001H26.3999C26.7866 22.4001 27.0999 22.0868 27.0999 21.7001V6.3001C27.0999 5.91342 26.7866 5.6001 26.3999 5.6001Z"/>
      </svg>
      Завершить покупки
    </a>
    <Modal :item="item"
           :posModal="posModal"
           :displayStartInfo="displayStartInfo"
           :screenNumber="screenNumber"
           v-if="showModal && (displayDetails || displayStartInfo)" >
      <ItemDetails :item="item" :posModal="posModal" :shelfId="shelfId" :disableBtn="basketItemsQnty > 9" v-if="displayDetails" />
      <StartInfo :screenNumber="screenNumber" v-else-if="displayStartInfo" />
    </Modal>
    <MobileDrag :src="mobileSrc" :pos="mobilePos" v-if="mobileSrc"/>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import Modal from "@/components/Modal";
import Case1 from "@/components/Case-1";
import Case2 from "@/components/Case-2";
import DragList from "@/components/DragList";
import ItemDetails from "@/components/modal-content/ItemDetails";
import StartInfo from "@/components/modal-content/StartInfo";
import Arrow from "@/components/Arrow";
import MobileDrag from "@/components/MobileDrag";

export default {
  name: 'Shelves',
  data() {
    return {
      id: '',
      con: '',
      screenId: null,
      ready: false,

      horPosition: null,
      maxScrollLeft: null,
      scrollGap: null,
      isTouch: false,

      screenCase: 'Case1',

      screens: [],
      initialZones: [],
      screenNumber: 1,
      zones: [],
      basketZone: {
        id: '0',
        name: "basket",
        type: 'basket',
        racks: [
          {
            id: '0',
            uuid: '0',
            pens: []
          }
        ]
      },
      shownProductsIds: [],
      item: {},
      showModal: true,
      displayStartInfo: true,
      posModal: null,
      shelfId: '',

      mobileSrc: '',
      mobilePos: {
        top: 0,
        left: 0
      },

      options: {
        dropzoneSelector: ".drag-shelf-content",
        draggableSelector: ".drag-item",
        onDragstart: (e) => {
          let oldGroupId = e.owner.dataset.id
          let elementId = e.items[0].dataset.id
          if (oldGroupId == this.basketGroupId && e.droptarget === null) {
            setTimeout(() => {
              this.removeElementFromBasket(elementId)
            }, 500)
          }
        },
        onDragend: async (e) => {
          let newGroupId = e.droptarget.dataset.id
          let oldGroupId = e.owner.dataset.id
          let elementId = e.items[0].dataset.id
          if (newGroupId == oldGroupId) {
            e.stop()
          } else if (newGroupId == this.basketGroupId && oldGroupId != this.basketGroupId) {
            this.putElementToBasket(oldGroupId, elementId)
            if (this.$gtm.enabled()) {
              this.$gtm.trackEvent({
                event: 'Basket-Add',
                category: 'Basket',
                action: 'Add',
                label: elementId,
                value: new Date(),
              })
            }

            // track
            try {
                await this.track({
                    e: e.items[0],
                    event: "add_to_basket",
                    screen: this.screenNumber,
                })
            } catch (e) {
              console.error(e)
            }


          } else if (oldGroupId == this.basketGroupId) {
            this.removeElementFromBasket(elementId)
          } else {
            e.stop()
          }
          e.stop()
        }
      },
    };
  },
  components: {
    Modal, Case1, Case2, DragList, ItemDetails, StartInfo, Arrow, MobileDrag
  },
  methods: {
    wheel(e) {
      if (e.deltaY) {
        this.$refs.horizontal.scrollLeft += e.deltaY;
      } else if (e.deltaX) {
        this.$refs.horizontal.scrollLeft += e.deltaX;
      }
      this.horPosition = this.$refs.horizontal.scrollLeft;
    },
    scrollHorizontal(dir) {
      const scrollLeft = this.isTouch ? this.$refs.horizontal.scrollLeft - this.inRem(246) : 0;
      const scrollRight = this.isTouch ? this.$refs.horizontal.scrollLeft + this.inRem(246) : this.maxScrollLeft;
      if (dir === 'left') {
        this.$refs.horizontal.scrollTo({left: scrollLeft, behavior: 'smooth'})
        this.horPosition = scrollLeft;
      } else {
        this.$refs.horizontal.scrollTo({left: scrollRight, behavior: 'smooth'})
        this.horPosition = scrollRight;
      }
    },

    async fetchData(con, screenId) {
      try {
        await this.$http.get(`${process.env.VUE_APP_API_URL}/api/scripts/${con}${screenId ? '/' + screenId : ''}`)
          .then( response => {
            return response.data;
          })
          .then( data => {
            if (data.zones) {
              this.initialZones = data.zones;
              if (this.initialZones && this.initialZones.length > 0) {
                this.initialZones.push(this.basketZone);
              }
              this.zones = this.cloneOverJson(this.initialZones);

              this.screens = data.screen_ids

              this.screenId = this.screenId === null ? data.screen_ids[0] : data.screen_ids[this.screenNumber];

              setTimeout(() => {
                this.maxScrollLeft = this.$refs.horizontal.scrollWidth - this.$refs.horizontal.clientWidth;
                this.scrollGap = this.maxScrollLeft / 10;
              }, 500)
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    putElementToBasket(oldGroupId, elementId) {
      if (this.zones[this.basketZoneIndex].racks[0].pens.length < 10) {
        const {zoneIndex, groupIndex, elementIndex} = this.findElement(elementId, this.zones, oldGroupId, 'put');
        const clonedElement = this.cloneOverJson(this.zones[zoneIndex].racks[groupIndex].pens[elementIndex]);
        // this.zones[zoneIndex].racks[groupIndex].pens[elementIndex].image = '';

        this.zones[this.basketZoneIndex].racks[0].pens.push(clonedElement);
      }
    },
    removeElementFromBasket(elementId) {
      /*const {zoneIndex, groupIndex, elementIndex} = this.findElement(elementId, this.initialZones, elementId, 'remove')

      const removedElement = this.cloneOverJson(this.zones[this.basketZoneIndex].racks[0].pens
          .splice(this.zones[this.basketZoneIndex].racks[0].pens.findIndex(el => el.uuid == elementId), 1)[0])

      this.zones[zoneIndex].racks[groupIndex].pens[elementIndex].image = removedElement.image*/

      this.cloneOverJson(this.zones[this.basketZoneIndex].racks[0].pens
          .splice(this.zones[this.basketZoneIndex].racks[0].pens.findIndex(el => el.uuid == elementId), 1)[0]);
    },
    findElement(elementId, zones, id, action) {
      const zoneIndex = action === 'put'
          ? zones.findIndex(zone => zone.racks.some(group => group.uuid == id))
          : zones.findIndex(zone => zone.racks.some(group => group.pens.some(el => el.uuid == elementId)));
      const groupIndex = action === 'put'
          ? zones[zoneIndex].racks.findIndex(group => group.uuid == id)
          : zones[zoneIndex].racks.findIndex(group => group.pens.some(el => el.uuid == elementId));
      const elementIndex = zones[zoneIndex].racks[groupIndex].pens.findIndex(el => el.uuid == elementId);
      return {zoneIndex, groupIndex, elementIndex};
    },

    async nextScreen() {

      // track shown (mouse over) products
      try {
          await this.track({
              screen: this.screenNumber,
              event: 'next_screen',
              shown: this.shownProductsIds
          })
      } catch (e) {
        console.error(e)
      } finally {
        this.shownProductsIds = []
      }

      const arr = this.basket.racks[0].pens.map(pen => pen.id),
          basketProductsIds = arr.join('%2C');

      if (this.screenNumber !== 3) {
        this.screenId = this.screens[this.screenNumber]
        await this.fetchData(this.con, this.screenId);
        this.displayStartInfo = true;
        this.showModal = true;
        this.posModal = null;
        this.screenNumber++;
      } else {
        window.location.href = 'https://srv.kg/05/KBR2306/cgi-bin/ciwweb.pl?studyname=KBR2306&ge=1&ID='
            + this.id
            + '&prodIds=' + basketProductsIds;
      }
    },

    modalPosition(data) {
      let pos = data.pos

      if (data.pos) {
        const modalWidth = {
          top: this.$refs.horizontal.offsetHeight - this.inRem(90),
          left: this.inRem(345 / 2)
        };
        let left = `${data.pos.left}px`;

        if (this.$refs.horizontal.offsetWidth - this.inRem(345 / 2) < data.pos.left) {
          left = `${this.$refs.horizontal.offsetWidth - this.inRem(345 / 2)}px`
        } else if (modalWidth.left > data.pos.left) {
          left = `${modalWidth.left}px`;
        }

        pos = {
          top: (modalWidth.top - data.pos.top < this.inRem(90)) ? `${modalWidth.top}px` : `${data.pos.top + this.inRem(90)}px`,
          left: left
        };

        setTimeout(() => {
          if (this.posModal) {
            this.showModal = true;
          }
        }, 200)
      } else {
        this.showModal = true;
      }

      this.item = data.item;
      this.posModal = pos;
      if (data.shelfId) {
        this.shelfId = data.shelfId;
      }
    },

    windowWidthCount() {
      this.isTouch = (window.innerWidth < 769) ||
          (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0))
      ? true : false;
    }

  },
  computed: {
    basket() {
      return this.zones.find(zone => zone.type === 'basket');
    },
    basketGroupId() {
      return this.basket.racks[0] ? this.basket.racks[0].uuid : null;
    },
    basketZoneIndex() {
      return this.zones.findIndex(zone => zone.type === 'basket');
    },
    // defines is it's an item details popup or message popup
    displayDetails() {
      return this.item && Object.keys(this.item).length > 0
    },
    // products quantity in basket
    basketItemsQnty() {
      return this.basket && this.basket.racks[0].pens ? this.basket.racks[0].pens.length : 0
    }
  },
  watch: {
    screenId(value) {
      this.screenCase = value === 1 ? 'Case2' : 'Case1'
    }
  },
  mounted() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    this.id = url.searchParams.get("ID");
    this.con = url.searchParams.get("con");

    this.fetchData(this.con);

    window.addEventListener('load', this.windowWidthCount)

    window.addEventListener('resize', this.windowWidthCount)
  },
  created () {
    eventBus.$on('open modal', data => {
      if (data.pos != null) {
        // have pos - hover event, not click (wtf)
        this.shownProductsIds.push(data.item.id)
      }

      this.modalPosition(data)
    })
    eventBus.$on('close modal', () => {
      this.item = {};
      this.showModal = false;
      this.displayStartInfo = false;
    })
    eventBus.$on('remove item', elementId => {
      this.removeElementFromBasket(elementId);
      if (this.item.uuid === elementId) {
        this.item = {};
        this.showModal = false;
      }
    })
    eventBus.$on('mobile drag', (data) => {
      this.mobileSrc = data.src;
      this.mobilePos.top = data.pos.top;
      this.mobilePos.left = data.pos.left;
    })
    eventBus.$on('move to basket', async (data) => {
        this.putElementToBasket(data.shelf, data.uuid)

        // track
        try {
            await this.track({
                e: document.querySelectorAll(`li[data-id="${data.uuid}"]`)[0],
                event: "add_to_basket",
                screen: this.screenNumber,
            })
        } catch (e) {
            console.error(e)
        }
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.windowWidthCount)
  }
};
</script>

<style lang="scss">
@import "../assets/scss/common";

.horizontal-scroll {
  background: url('../assets/img/bg-1.png') no-repeat;
  background-position: right;
  background-size: auto 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.drag-container {
  position: relative;
  height: 100vh;
  width: max-content;
  padding: 68rem 54rem 68rem 79rem;
  overflow: hidden;
  background: url('../assets/img/bg-1.png') no-repeat;
  background-size: cover;
}

.link-button {
  position: fixed;
  right: 59rem;
  bottom: 4rem;
  padding: 14rem 79rem;
  background-color: transparent;
  border-color: transparent;
  color: $white;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 768px) {
    top: 16rem;
    right: 16rem;
    bottom: auto;
    width: calc(100% - 32rem);
    padding: 14rem;
  }

  svg {
    width: 28rem;
    height: 28rem;
    margin-right: 12rem;
    fill: $white;
    transition: fill .25s ease-out;
  }

  &:hover {
    color: $blue;
    background-color: transparent;
    border-color: transparent;

    svg {
      fill: $blue;
    }
  }
}

</style>
